import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import { Link } from "gatsby";
import moment from "moment-mini";

import Main from "../components/main";
import Footer from "../components/section/footer";
import LoginControl from "../controls/login";
import Layout from "../components/section/layout";
import Seo from "../components/section/seo";
import { createVerificationMutation, getAdminGeneratedVerification, getCurrentUserQuery, resendVerificationMutation, validateVerification } from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import ForgotPassword from "../components/forgot-password/index";
import Header from "../components/section/header";
import Banner from "../components/section/banner";
import DataHelper from "../components/utils/data-helper";
import OtpInput from "../components/otp-input";

const OTP_VALIDITY = 600;

export default function Index(props) {
  return (<Main>
    <Layout>
      <Seo title="SpeedyTel - Portal Login" />
      <UserCheck {...props} />
    </Layout>
  </Main>)
}

const UserCheck = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (user) {
    return window.location = "/portal";
  }

  return (
    <LoginPage {...props} />
  );
}

const LoginPage = (props) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp"]);
  const [resendVerfication, {loading}] = useMutation(resendVerificationMutation);
  const [requestCode, setRequestCode] = useState(null);
  const [retryEnabled, setRetryEnabled] = useState(false);
  const [retryTimer, setRetryTimer] = useState(0);
  const [userData, setUserData] = useState(null);
  const [createVerification] = useMutation(createVerificationMutation);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const [inputFocus, setInputFocus] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);

  const {data: validateVerificationData, startPolling, stopPolling} = useQuery(validateVerification, {
    variables: {requestCode},
  });
  const requestCodeVerified = validateVerificationData?.classMethods?.Verification?.validateVerification;

  const {data} = useQuery(getAdminGeneratedVerification, {
    variables: {
      userId: DataHelper.toGlobalId("User", userData?.id),
    },
  });

  const activeVerification = data?.classMethods?.Verification?.adminGeneratedVerification;


  useEffect(() => {
    if (userData) {
      setShowMfa(false);
    }
  }, []);

  useEffect(() => {
    if (requestCodeVerified) {
      handleApprove();
      stopPolling();
    }
  }, [requestCodeVerified]);

  useEffect(() => {
    let interval;

    if (retryTimer === 0) {
      setOtp(Array(6).fill("")); // resets otp value
      setInputFocus(null); // resets input focus
      setOtpError(null); // resets otp error
    }

    if (retryTimer > 0 && !requestCodeVerified) {
      interval = setInterval(() => {
        setRetryTimer(prev => {
          const newTime = prev - 1;

          if (newTime === 0) {
            stopPolling();
            setRetryEnabled(true);
          }

          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [retryTimer]);

  useEffect(()=>{
    if (otp.join("").length === 6) {
      handleOtpSubmit(otp.join(""));
    }
  }, [otp]);

  useEffect(() => {
    setOtp(Array(6).fill("")); // resets otp value
    setOtpError(null); // resets otp error
    setInputFocus(null); // resets input focus

    if (mfaMethod === "sms") {
      createVerification({
        variables: {
          type: "SMS",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Sms Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then(result => {
        const {data} = result;
        setRequestCode(data?.classMethods?.Verification?.createVerification?.id);
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });

    } else if (mfaMethod === "email") {
      createVerification({
        variables: {
          type: "EMAIL",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Email Verification",
          template: "2fa-portal",
        },
        awaitRefetchQueries: true,
      }).then(result => {
        const {data} = result;
        setRequestCode(data?.classMethods?.Verification?.createVerification?.id);
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    } else if (mfaMethod === "user-totp") {
      createVerification({
        variables: {
          type: "TOTP",
          userId: DataHelper.toGlobalId("User", userData?.id),
          name: "Totp Verification",
        },
        awaitRefetchQueries: true,
      }).then(result => {
        const {data} = result;
        setRequestCode(data?.classMethods?.Verification?.createVerification?.id);
      });
    }
  }, [mfaMethod]);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  const handleRetry = () => {
    setOtp(Array(6).fill("")); // resets otp value
    setInputFocus(null); // resets input focus
    setOtpError(null); // resets otp error

    if (requestCode) {
      resendVerfication({
        variables: {requestCode},
      }).then(() => {
        setRetryEnabled(false);
        setRetryTimer(OTP_VALIDITY);
        startPolling(500);
      });
    }
  };

  const handleApprove = async (verificationId = null) => {
    try {
      const apiPath = process.env.BACKEND_URL;
      const data = await fetch(`${apiPath}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({requestCode: verificationId || requestCode}),
      });

      if (data?.status === 503) {
        console.log("503 redirect?"); // eslint-disable-line
        setShowMfa(false);
      }

      if (data?.status === 401) {
        setShowMfa(false);
      }

      const parsedData = await data.json();

      if (parsedData.jwtToken) {
        localStorage.setItem("authToken", parsedData.jwtToken);
      }

      window.location.href = process.env.PORTAL_URL;
    } catch (err) {
      console.log("ERROR >>> ", err); // eslint-disable-line
      console.log("err 503 redirect?"); // eslint-disable-line
    }
  };

  const MfaOption = ({label, hint, onClick, disabled, icon}) => {
    return (
      <div
        className={`mfa-option ${disabled && "disabled"}`}
        onClick={() => {
          if (!disabled && onClick) {
            onClick();
          }
        }}
      >
        {icon}
        <div className="mfa-option-content">
          <div className="mfa-option-label">{label}</div>
          <p>{hint}</p>
        </div>
        <i className="fa fa-angle-right"/>
      </div>
    );
  };

  const MFAselectPanel = () => {
    const MFA_OPTIONS = [
      {label: "SMS Authentication", hint: "Receive authentication code on your registered mobile number.", value: "sms", icon: <i className="fas fa-comment"/>},
      {label: "Email Authentication", hint: "Receive authentication code on your registered email address.", value: "email", icon: <i className="fas fa-envelope"/>},
      {label: "2FA Authentication", hint: "Receive authentication code on your registered authenticator app.", value: "user-totp", icon: <i className="fas fa-key"/>},
      {label: "I have a one-time-password", hint: "You received authentication code from your system admin.", value: "admin-generated-otp", icon: <i className="fal fa-key"/>},
    ];

    return (
      <div className="mfa-panel">
        <p className="mfa-heading">{"Verify your account"}</p>
        <p>{"Select an one-time verification method."}</p>
        <p>{"This is a process called Multi-Factor Authentication (MFA), which has been introduced to strengthen the security of your account."}</p>
        <div className="mfa-options">
          {MFA_OPTIONS.map(option => ({...option, disabled: !allowedOptions.find(ao => ao === option.value)}))
            .filter(o => !(o.value === "user-totp" && o.disabled)) // totally hides user-totp option when no authenticator setup
            .map(option => (
              <MfaOption
                key={option.label}
                label={option.label}
                hint={option.hint}
                disabled={option.disabled}
                onClick={() => {
                  setMfaMethod(option.value);
                }}
                icon={option.icon}
              />
            ))
          }
        </div>
        <div className="mfa-other-options-container">
          <span
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
          >{"Cancel"}</span>
        </div>
      </div>
    );
  };

  const handleOtpSubmit = async (otp) => {
    setOtpLoading(true);
    const apiPath = process.env.BACKEND_URL;
    const data = await fetch(`${apiPath}rest.api/verify/${requestCode}:${otp}`, {
      method: "GET",
    });

    const parsedData = await data.json();

    if (parsedData.success) {
      handleApprove();
    } else {
      setOtpError("Invalid code");
    }

    setOtpLoading(false);
  };

  const MFASmsPanel = () => {
    return (
      <div className="mfa-panel">
        <p className="mfa-heading pb-3">{"Enter your code"}</p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          `An sms has been sent to your registered mobile number that ends with ${DataHelper.characterHide(userData?.mobileNumber)}`}
        </p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "Please click the approval link provided or enter the code below to continue."}
        </p>
        {loading ? <i className="fad fa-spinner"/> : <OtpInput
          count={6}
          value={otp}
          onChange={value => {
            setOtp(value);
            setOtpError(null);
          }}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
        />}
        {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
        <div className="mfa-actions-container">
          {!requestCodeVerified && retryEnabled && <div className="retry-container">
            <div className="retry-info">{"Request code expired"}</div>
            <Button
              variant="login"
              onClick={handleRetry}
            >
              {"Retry"}
            </Button>
          </div>}
          {!requestCodeVerified && !retryEnabled && retryTimer > 0 && <div className="retry-timer">
            {`expires in ${retryTimer > 60 ? moment.utc(retryTimer * 1000).format("mm:ss") : `${retryTimer} ${retryTimer > 1 ? "seconds" : "second"}`}`}
          </div>}
        </div>
        <div className="mfa-other-options-container">
          <span
            onClick={() => {
              setMfaMethod(null);
              stopPolling();
              setRetryTimer(0);
            }}
          >
            {"Use another method to receive the code"}
          </span>
          <span
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
          >{"Sign in to a different account"}</span>
        </div>
      </div>
    );
  };

  const MFAEmailPanel = () => {
    return (
      <div className="mfa-panel">
        <p className="mfa-heading pb-3">{"Enter your code"}</p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          `An email has been sent to your registered email address that ends with ${DataHelper.characterHide(userData?.email)}`}
        </p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "Please click the approval link provided or enter the code below to continue."}
        </p>
        {loading ? <i className="fad fa-spinner"/> : <OtpInput
          count={6}
          value={otp}
          onChange={value => {
            setOtp(value);
            setOtpError(null);
          }}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
        />}
        {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
        <div className="mfa-actions-container">
          {!requestCodeVerified && retryEnabled && <div className="retry-container">
            <div className="retry-info">{"Request code expired"}</div>
            <Button
              variant="login"
              onClick={handleRetry}
            >
              {"Retry"}
            </Button>
          </div>}
          {!requestCodeVerified && !retryEnabled && retryTimer > 0 && <div className="retry-timer">
            {`expires in ${retryTimer > 60 ? moment.utc(retryTimer * 1000).format("mm:ss") : `${retryTimer} ${retryTimer > 1 ? "seconds" : "second"}`}`}
          </div>}
        </div>
        <div className="mfa-other-options-container">
          <span
            onClick={() => {
              setMfaMethod(null);
              stopPolling();
              setRetryTimer(0);
            }}
          >
            {"Use another method to receive the code"}
          </span>
          <span
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
          >{"Sign in to a different account"}</span>
        </div>
      </div>
    );
  };

  const MFATotpPanel = () => {
    return (
      <div className="mfa-panel">
        <p className="mfa-heading pb-3">{"Enter your code"}</p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "We have identified that you have registered a 2FA device."}
        </p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "Enter the code from the registered device to continue."}
        </p>
        <OtpInput
          count={6}
          value={otp}
          onChange={value => {
            setOtp(value)
            setOtpError(null)
          }}
          inputFocus={inputFocus}
          setInputFocus={setInputFocus}
        />
        {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
        <div className="mfa-other-options-container">
          <span onClick={() => setMfaMethod(null)}>
            {"Use another method to receive the code"}
          </span>
          <span
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
          >{"Sign in to a different account"}</span>
        </div>
      </div>
    );
  };

  const MFAAdminGeneratedPanel = () => {
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [otpError, setOtpError] = useState(null);
    const [otpLoading, setOtpLoading] = useState(false);
    if (!loading && !activeVerification && activeVerification === null) {
      return (
        <div className="mfa-panel admin-generated-container">
          <p>{"Oh no! It seems like there are no available one-time password at the moment."}</p>
          <Button
            variant="login"
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
            disabled={loading}
          >
            {"Back to Login"}
          </Button>
        </div>
      );
    }

    useEffect(()=>{
      if (activeVerification && otp.join("").length === 6) {
        handleOtpSubmit(otp.join(""));
      }
    }, [otp]);

    const handleOtpSubmit = async (otp) => {
      setOtpLoading(true);
      const apiPath = process.env.BACKEND_URL;
      const data = await fetch(`${apiPath}rest.api/verify/${activeVerification.id}:${otp}`, {
        method: "GET",
      });

      const parsedData = await data.json();

      if (parsedData.success) {
        handleApprove(activeVerification.id);
      } else {
        setOtpError("Invalid code");
      }

      setOtpLoading(false);
    };

    return (
      <div className="mfa-panel">
        <p className="mfa-heading pb-3">{"Enter your code"}</p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "You have received a one-time-passcode from the admin."}
        </p>
        <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
          "Enter the code below to continue."}
        </p>
        <OtpInput
          count={6}
          value={otp}
          onChange={value => {
            setOtp(value);
            setOtpError(null);
          }}
        />
        {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
        <div className="mfa-other-options-container">
          <span onClick={() => setMfaMethod(null)}>{"Use another method to receive the code"}</span>
          <span
            onClick={() => {
              setShowMfa(false);
              setMfaMethod(null);
            }}
          >{"Sign in to a different account"}</span>
        </div>
      </div>
    );
  };

  const MfaPanel = () => {
    return (
      <Row>
        <Col xs={12}>
          {!mfaMethod && <MFAselectPanel/>}
          {mfaMethod === "sms" && <MFASmsPanel/>}
          {mfaMethod === "email" && <MFAEmailPanel/>}
          {mfaMethod === "user-totp" && <MFATotpPanel/>}
          {mfaMethod && mfaMethod === "admin-generated-otp" && <MFAAdminGeneratedPanel/>}
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Header />
      <Banner>
        <Row className="fh-element align-items-center">
          <Col>
            {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
            <Container>
              <Row>
                <Col lg={8} xl={6} className="mb-5 mb-lg-0">
                  <Modal
                    show={showMfa && userData}
                    className="login-mfa-panel-modal"
                  >
                    <MfaPanel/>
                  </Modal>
                  {!showMfa && <Container>
                    <div className="login-container">
                      <div className="title mb-2">
                        {"Login"}
                      </div>
                      <LoginControl
                        authUrl={process.env.AUTH_URL}
                        portalUrl={process.env.PORTAL_URL}
                        apiUrl={process.env.BACKEND_URL}
                        setShowMfa={setShowMfa}
                        setAllowedOptions={setAllowedOptions}
                        setUserData={setUserData}
                      >
                        <Col xs={12} sm={6} className="vw-loginbtn">
                          <a className="forgot-password" onClick={handleClickForgotPassword}>{"Forgot Password?"}</a>
                        </Col>
                      </LoginControl>
                      <Container>
                        <Row className="row-line-divider">
                          <Col className="p-0 d-flex align-items-left">
                            <Link
                              to="/"
                              className="ml-auto mt-3 btn-black-text">
                              {"Sign up here"}
                            </Link>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Container>}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Banner>
      <Footer />
    </Fragment>
  );
}



