import React, { Fragment, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Formsy from "formsy-react";
import TextField from "../components/bootstrap/input";
import { fetch, localStorage, window } from "window-or-global";
import { useQueryParam, StringParam } from "use-query-params";
import Terms from "../components/portal/terms";
import CriticalInformation from "../components/portal/critical-information";
import { useState } from "react";

export default function LoginControl(props) {
  let dst;
  const { authUrl, portalUrl, apiUrl, setShowMfa, setAllowedOptions, setUserData } = props;

  const formRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [authDetails, setAuthDetails] = useState(false);
  const [showTerm, setTerm] = useState(false);
  const [showPolicy, setPolicy] = useState(false);

  const [forgotAlert] = useQueryParam("forgot", StringParam);

  const handleValidLoginSubmit = async (data) => {
    setProcessing(true);
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...data }),
      });

      if (response.status === 503) {
        setProcessing(false);
        setLoginError("Something went wrong");
        return;
      }

      const parsedResponse = await response.json();

      if (parsedResponse?.success === "Invalid username/password") {
        setProcessing(false);
        setLoginError("Invalid username and/or password.");
      }

      if (response.status === 401) {
        setProcessing(false);
        setLoginError(parsedResponse.error);
      }

      if (parsedResponse.success && parsedResponse.user && !parsedResponse.jwtToken) {
        if (parsedResponse.user.mobileNumber) {
          setAllowedOptions(prev => [...prev, "sms"]);
        }

        if (parsedResponse.user.email) {
          setAllowedOptions(prev => [...prev, "email"]);
        }

        if (parsedResponse.hasUserTOTP) {
          setAllowedOptions(prev => [...prev, "user-totp"]);
        }

        setUserData(parsedResponse.user);

        await setShowMfa(true);
        return;
      } else {
        if (parsedResponse.jwtToken) {
          localStorage.setItem("authToken", parsedResponse.jwtToken);
        }
  
        if (parsedResponse.user.id) {
          setAuthDetails(data);
          if (authUrl && authUrl !== "") {
            return formRef.current.submit();
          } else {
            window.location = portalUrl;
          }
        }
      }
    } catch (err) {
      console.log("login error", err);
      setProcessing(false);
      setLoginError("Invalid username and/or password.");
    }

    setProcessing(false);
  }

  return (
    <Fragment>
      {showTerm && <Terms onClose={() => setTerm(false)} />}
      {showPolicy && <CriticalInformation onClose={() => setPolicy(false)} />}
      <form ref={formRef} name="login" action={authUrl} method="post">
        <input type="hidden" value="hotspot" name="radius11" />
        <input type="hidden" name="dst" value={`${dst || portalUrl}`} />
        <input type="hidden" name="username" value={authDetails?.userName} />
        <input type="hidden" name="password" value={authDetails?.password} />
      </form>
      <Formsy
        className="vw-login-panel"
        onValidSubmit={handleValidLoginSubmit}
        disabled={processing}
      >
        <Container fluid>
          {loginError && (
            <Row>
              <Col>
                <div className="alert alert-danger mb-0">{loginError}</div>
              </Col>
            </Row>
          )}
          {forgotAlert === "true" && (
            <Row>
              <Col>
                <div className="alert alert-success mb-0">
                  {"Your password has been changed."}
                  <br />
                  {"Please check your email for your new password"}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} className="vw-login-field">
              <TextField
                className="vw-form-field"
                name="userName"
                autoComplete="off"
                placeholder="Username"
                disabled={processing}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="vw-login-field">
              <TextField
                className="vw-form-field"
                name="password"
                autoComplete="off"
                placeholder="Password"
                type="password"
                disabled={processing}
                required
              />
            </Col>
          </Row>
          <Row className="vw-login-buttons">
            {props.children}
            <Col xs={12} sm={6} className="vw-loginbtn">
              <Button
                className="vw-button btn-login"
                type="submit"
                disabled={processing}
              >
                {"Login"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </Fragment>
  );
}
